import Image from "next/image";
import { BsWhatsapp } from "react-icons/bs";
import {
  FACEBOOK_PAGE_URL,
  INSTAGRAM_PAGE_URL,
  WHATSAPP_HREF,
} from "../utils/constants";
import FollowUsNotification from "./FollowUsNotification";
import { InstagramIcon, FacebookIcon } from "./icons";
import { classNames } from "../utils/formatters";

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  solutions: [
    { name: "Buscar autos usados", href: "/autosusados" },
    { name: "Vender mi auto", href: "/vender" },
    { name: "Conocé el valor de tu auto", href: "/valor-de-mi-auto" },
    { name: "Favoritos", href: "/favorites" },
    { name: "Directorio", href: "/directorio" },
  ],
  support: [
    { name: "Precios", href: "/pricing" },
    // { name: "Preguntas frecuentes", href: "/faq" },
    { name: "Contacto", href: "mailto:soporte@moviautos.com" },
  ],
  company: [
    // { name: "Acerca de", href: "#" },
    { name: "Blog", href: "/blog" },
    { name: "Contacto", href: "mailto:contacto@moviautos.com" },
  ],
  legal: [
    // { name: "Claim", href: "#" },
    { name: "Política de privacidad", href: "/politica-de-privacidad" },
    { name: "Terminos y condiciones de uso", href: "/terminos-y-condiciones" },
  ],
  social: [
    {
      name: "Facebook",
      href: FACEBOOK_PAGE_URL,
      icon: FacebookIcon,
    },
    {
      name: "Instagram",
      href: INSTAGRAM_PAGE_URL,
      icon: InstagramIcon,
    },
    {
      name: "Whatsapp",
      href: WHATSAPP_HREF,
      icon: (props) => <BsWhatsapp className="h-5 w-5 pt-0.5" />,
    },
  ],
};

export default function Footer({
  orange,
  showFollowUsBanner = true,
}: {
  orange?: boolean;
  showFollowUsBanner?: boolean;
}) {
  const categoryTextColor = orange ? "text-white" : "text-gray-400";
  const linkTextColor = orange
    ? "text-neutral-100 hover:text-white"
    : "text-gray-500 hover:text-gray-900";
  const linkIconColor = orange
    ? "text-neutral-100 hover:text-white"
    : "text-gray-400 hover:text-gray-500";
  const textColor = orange ? "text-neutral-100" : "text-gray-400";

  return (
    <footer
      className={classNames(orange ? "bg-orange-500" : "bg-white")}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {showFollowUsBanner ? <FollowUsNotification fixed /> : null}
        <div className="xl:grid xl:grid-cols-3 xl:gap-8 mt-12">
          <div className="space-y-8 xl:col-span-1">
            <Image
              src="/movi_logo_min_gray_200.png"
              alt="Logo Movi"
              width={62}
              height={32}
            />
            <p className={classNames("text-base", linkTextColor)}>
              Movi es el sitio web más fácil y seguro para comprar y vender
              autos usados en <span className="sr-only">Costa Rica</span>🇨🇷
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className={linkIconColor}
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p
              className={classNames(
                " text-base",
                orange ? "text-neutral-100" : "text-gray-500"
              )}
            >
              Whatsapp{" "}
              <a
                href={WHATSAPP_HREF}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  " cursor-pointer",
                  orange
                    ? "text-neutral-200 hover:text-white underline"
                    : "text-cyan-500 hover:text-cyan-400"
                )}
              >
                +506 8699-1661
              </a>
            </p>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3
                  className={classNames(
                    "text-sm font-semibold tracking-wider uppercase",
                    categoryTextColor
                  )}
                >
                  Soluciones
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames("text-base ", linkTextColor)}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3
                  className={classNames(
                    "text-sm font-semibold tracking-wider uppercase",
                    categoryTextColor
                  )}
                >
                  Soporte
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          "text-base hover:text-gray-900",
                          linkTextColor
                        )}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3
                  className={classNames(
                    "text-sm font-semibold tracking-wider uppercase",
                    categoryTextColor
                  )}
                >
                  Compañía
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          "text-base hover:text-gray-900",
                          linkTextColor
                        )}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3
                  className={classNames(
                    "text-sm font-semibold tracking-wider uppercase",
                    categoryTextColor
                  )}
                >
                  Legal
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          "text-base hover:text-gray-900",
                          linkTextColor
                        )}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "mt-12 border-t pt-8",
            orange ? "border-orange-400" : "border-gray-200"
          )}
        >
          <p className={classNames("text-base xl:text-center", textColor)}>
            &copy; {new Date().getFullYear()} Inversiones Movi JJC SRL. Todos
            los derechos reservados.
          </p>
          <p className={classNames("text-xs xl:text-center", textColor)}>v2</p>
        </div>
      </div>
    </footer>
  );
}
