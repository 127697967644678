import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import useLocalStorage from "../hooks/useLocalStorage";
import { event } from "../lib/gtag";
import FBLink from "./FBLink";
import IGLink from "./IGLink";

function FolloUsContent() {
  function onIGClick() {
    event({
      action: "click_follow",
      category: "Social",
      label: "Instagram",
    });
  }

  function onFBClick() {
    event({
      action: "click_follow",
      category: "Social",
      label: "Facebook",
    });
  }

  return (
    <div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium text-gray-900">¡Seguinos en redes!</p>
      <p className="mt-1 text-sm text-gray-500">
        Seguinos en Instagram y Facebook para estar al día con noticias de Movi
        y recibir el mejor contenido automovilístico.
      </p>
      <div className="mt-3 flex items-center space-x-7">
        <IGLink onClick={onIGClick} />
        <FBLink onClick={onFBClick} />
      </div>
    </div>
  );
}

export default function FollowUsNotification({ fixed }: { fixed?: boolean }) {
  const [show, setShow] = useLocalStorage("showFollowUs", true);

  function close() {
    setShow(false);
  }

  if (fixed) {
    return (
      <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <UserPlusIcon
                className="h-6 w-6 text-orange-500 animate-bounce"
                aria-hidden="true"
              />
            </div>
            <FolloUsContent />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <UserPlusIcon
                      className="h-6 w-6 text-orange-500 animate-bounce"
                      aria-hidden="true"
                    />
                  </div>
                  <FolloUsContent />
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2"
                      onClick={close}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
